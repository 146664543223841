import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  navList: [
    {
      label: "CATEGORIES",
      value: "/category",
      url: "/category",
      children: [],
    },
    // { label: "ORDER STATUS", value: "/order", url: "/order" },
  ],
  userInfo: {},
  token: "",
  searchKeyword: "",
  websiteInfo: {}
}

const mutations = {
  setNavList(state, payload) {
    state.navList = payload;
  },
  setUserInfo(state, payload) {
    state.userInfo = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setSearchKeyword(state, payload) {
    state.searchKeyword = payload;
  },
  setWebSiteInfo(state, payload) {
    state.websiteInfo = payload
  }
};

const actions = {
};
const store = new Vuex.Store({
  state,
  mutations,
  actions
})

export default store
