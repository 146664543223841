import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = [
	{
		path: '/',
		redirect: '/index'   // 重定向，输入/的时候直接进入首页
	},
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: {
      title: 'index',
    },
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/views/category/index.vue'),
    meta: {
      title: 'category',
    },
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('@/views/product/index.vue'),
    meta: {
      title: 'product',
    },
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/index.vue'),
    meta: {
      title: 'order',
    },
  },
  {
    path: '/refundPolicy',
    name: 'refundPolicy',
    component: () => import('@/views/textContent/refundPolicy.vue'),
    meta: {
      title: 'refundPolicy',
    },
  },
  {
    path: '/termsAndconditions',
    name: 'termsAndconditions',
    component: () => import('@/views/textContent/termsAndconditions.vue'),
    meta: {
      title: 'termsAndconditions',
    },
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/textContent/privacyPolicy.vue'),
    meta: {
      title: 'privacyPolicy',
    },
  },
  {
    path: '/search',
    name: 'SearchResult',
    component: () => import('@/views/search/index.vue'),
    meta: {
      title: 'search',
    },
  },
  {
    path: '/401',
    component: () => import('@/views/401.vue'),
    meta: {
      title: '401',
    },
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '404',
    },
  },
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  routes
})

const router = createRouter()

export default router
